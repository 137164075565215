import {useState, useEffect, useCallback} from "react"
import useFetch from 'use-http'
import enMessages from '../../localization/messages/en.json'
import frMessages from '../../localization/messages/fr.json'
import localisationDataservice from '../../localization/data/order';
import config from 'devextreme/core/config'
import { 
  locale,
  loadMessages
   } from "devextreme/localization"
  
//http://localhost:3000/orderfsdfsfsd?userid=f07eb929-1833-ec11-824b-0022483884d9&language=en&roles=%27Administrateur%20systdd%C3%A8me,dfddf%27
const useOrderService = () =>{

    const [state, setOrder] = useState()
    const [referential, setReferentail] = useState()
    const [backendError, setBackendError] = useState()
    const [crmContext, setCrmContext] = useState()
    const [notificationState, setNotificationState] = useState(false);
    const {
      post,
      get,
      response, 
      loading, 
       error,
    } = useFetch(process.env.REACT_APP_FUNCTIONS_URL)
    const systemAdminRule = 'Administrateur système'
    

    useEffect(() => {getOrder()}, [crmContext])
    const getOrder = async () =>{
      debugger
     
      if(!crmContext || !crmContext.orderId) {
        return
      }
      const init = await get(process.env.REACT_APP_FUNCTION_GET_SUBSCRIPTION_URL + crmContext.orderId)
      if (response.ok) setOrder(init)
      else if(init){ showNotification('error', init.reasonMessage) }   
    }
   
    useEffect(() => {getReferential()}, [crmContext])   
    const getReferential = async () =>{
      debugger
      if(!crmContext || !crmContext.language) {
        return
      }
      const init = await get(process.env.REACT_APP_FUNCTION_REFERENTIAL_URL + crmContext.language)
      if (response.ok) setReferentail(init)
      else if(init){ showNotification('error', init.reasonMessage) } 
    }

    useEffect(() => {getUrlParams()}, [])
    const getUrlParams = () =>{
      debugger
      const queryString = window.location.search;
      console.log(queryString);
      const urlParams = new URLSearchParams(queryString);
      setCrmContext({
        userId : urlParams.get('userid'),
        language : urlParams.get('language'),
        roles : urlParams.get('roles'),
        isAdmin : urlParams.get('roles').includes(systemAdminRule),
        orderId: urlParams.get('orderid')
     })
    }

    useEffect(() => {initLocalConfiguration()}, [crmContext])   
    const initLocalConfiguration= () =>{
      if(!crmContext || !crmContext.language) {
        return
      }
      initMessages()
      locale(crmContext.language)
      config({ defaultCurrency: 'EUR' })
  }
  const initMessages = () => {
      loadMessages(enMessages);
      loadMessages(frMessages);
      loadMessages(localisationDataservice.getDictionary());
  }
    const handleClientChange = (e) =>{
      if (!e.event || !e.event.currentTarget.name) return
      setOrder(prevState => ({
        ...prevState,
        client:{ 
            ...prevState.client,
            [e.event.currentTarget.name]: e.value
          }
      }))    
    }
    const handleSubscriptionChange = (e) =>{
      if (!e.event || !e.event.currentTarget.name) return    
      setOrder(prevState => ({
        ...prevState,
        subscription:{ 
          ...prevState.subscription,
          [e.event.currentTarget.name]: e.value
        }
      }))
    }
    const handleBirthDateChange = (e) =>{ 
      let updatedAge = null
      if(e.value !== null){
        let diff = Date.now() - new Date(e.value).getTime()
        let age = new Date(diff)
        updatedAge = Math.abs(age.getUTCFullYear() - 1970)
      }
      setOrder(prevState => ({
        ...prevState,
        client:{ 
            ...prevState.client,
            age: updatedAge,
            birthDate: e.value
          }
      }))
    }
    const handleCivilityChange = (e) =>{
      setOrder(prevState => ({
        ...prevState,
        client:{ 
            ...prevState.client,
            civility: e.value
          }
      })) 
    }
    const handleResidenceCountryChange = (e) =>{  
      setOrder(prevState => ({
        ...prevState,
        client:{ 
            ...prevState.client,
            residenceCountry: e.value
          }
      })) 
    }
    const handleProductChange = (e) => {
      debugger
     if(referential === undefined || !state)return;
      var productSelected = referential.products.filter((p)=> {
        return p.id === e.value
      })
      state.subscription.unitPrice = null
      setOrder(prevState => ({
        ...prevState,
        subscription:{ 
          ...prevState.subscription,
          product: e.value,
          unitPrice : productSelected.length > 0 ? productSelected[0].sharePrice : null
        }       
      }))
    }
    const handleStatusChange = (e) => {
      setOrder(prevState => ({
        ...prevState,        
        subscription:{ 
          ...prevState.subscription,
          status: e.value
        }   
      }))
    } 
    const handlePropertyTypeChange = (e) => {
      setOrder(prevState => ({
        ...prevState,        
        subscription:{ 
          ...prevState.subscription,
          shareType: e.value
        }   
      }))
    } 
    const handleSubscriptionDateChange = (e) => {
      setOrder(prevState => ({
        ...prevState,        
        subscription:{ 
          ...prevState.subscription,
          subscriptionDate: e.value
        } 
      }))
    }    
    const handleSignatureDateBsChange = (e) =>{ 
      debugger     
      setOrder(prevState => ({
        ...prevState,
        signature:{ 
          ...prevState.signature,
          date: e.value,
          isSignatureOk: e.value && state.signature.signaturePlace ? true : false
        }
      })) 
    } 
    const handleSignatureDateQeeriChange = (e) =>{
      setOrder(prevState => ({
        ...prevState,
        subscription:{ 
          ...prevState.subscription,
          signatureDateQeeri: e.value
        }        
      }))
    }
    const handleSignaturePlaceChange = (e) =>{  
      debugger    
      setOrder(prevState => ({
        ...prevState,
        signature:{ 
            ...prevState.signature,
            signaturePlace: e.value,
            isSignatureOk: e.value && state.signature.date ? true : false
          }
      })) 
    } 
    const handleSharesChange = (e) =>{
      setOrder(prevState => ({
        ...prevState,
        subscription:{ 
          ...prevState.subscription,
          share: e.value
        }        
      }))     
    }
    const handleAmountChange = (e) =>{
      debugger
      if( !state || !state.subscription) return
      let shares = parseFloat(e.value / state.subscription.unitPrice).toFixed(2)
      setOrder(prevState => ({
        ...prevState,
        subscription:{ 
          ...prevState.subscription,
          amount: e.value,
          share: shares > 0 ? shares : null
        }        
      }))
    }    
    const handleUnitPriceChange = (e) =>{
      if( !state || !state.subscription) return
      let shares = parseFloat(state.subscription.amount / e.value ).toFixed(2)  
      setOrder(prevState => ({
        ...prevState,
        subscription:{ 
          ...prevState.subscription,
          share : shares > 0 ? shares : null
        }         
      }))
    }   
    const handleSubmit = async (e) => {
      e.preventDefault();
      debugger
      state.callerId= crmContext.userId

      const update = await post(process.env.REACT_APP_FUNCTION_SAVE_SUBSCRIPTION_URL, state);
      if (response.ok){
        console.log ('save ok...')
        showNotification('info', 'La mise à jour a été effectuée avec succès')
      }
      else{
        setBackendError(update.reasonMessage)
        showNotification('error', update.reasonMessage)
      } 
    }
    const showNotification = (type, message)=>{
      setNotificationState({ 
        isVisible: true,
        type : type,
        message :message 
      })
    }
    const hideNotification = ()=>{
      setNotificationState({ 
        isVisible: false,
        type : '',
        message :'' 
      })
    }
    const getCivilityOptions = () => {
      return referential !== undefined ? referential.civilities : null
    }
    const getCountriesRef =() => {
       return referential !== undefined ? referential.countries : null
    }
    const getProductsRef = () => {
      return referential !== undefined ? referential.products : null
    }
    const getPropertyTypesRef = () => {
      return referential !== undefined ? referential.shareTypes : null
    }
    const getStatusRef = () => {
      return referential !== undefined ? referential.status : null
    }
    const onHidingNotification = () => {
      setNotificationState({ 
        isVisible: false,
        type : 'info',
        message : ''
      })
    }

    return {
      state,
      backendError,
      error,
      loading,
      crmContext,
      notificationState,
      handleClientChange,
      getCivilityOptions,
      getCountriesRef,
      getProductsRef,
      getPropertyTypesRef,
      getStatusRef,
      handleBirthDateChange,
      handleCivilityChange,
      handleResidenceCountryChange,
      handleProductChange,
      handleSubscriptionChange,
      handlePropertyTypeChange,
      handleSubscriptionDateChange,
      handleSignatureDateBsChange,
      handleSignatureDateQeeriChange,
      handleSignaturePlaceChange,
      handleSharesChange,
      handleAmountChange,
      handleUnitPriceChange,
      handleStatusChange,
      referential,
      handleSubmit,
      onHidingNotification
    }  
}

export default useOrderService