import React from 'react';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';//dx.material.teal.light.compact.css';
import { Order } from './components/order/order';
import { OrderList } from './components/orderList/orderList';
import './App.css';


function App() {

  return (
    <React.Fragment>
      <Order/>
    </React.Fragment>
  );
}

export default App;
