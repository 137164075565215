import React from "react"
import Form, { 
    SimpleItem,
    GroupItem,
    ButtonItem,
    Label,
    EmailRule,
    RequiredRule,
    PatternRule
    } from 'devextreme-react/form'
import TextBox from 'devextreme-react/text-box';
import Box, {
    Item,
  } from 'devextreme-react/box';
import { 
    formatMessage } from "devextreme/localization"
import { 
    Toast,
    Position } from 'devextreme-react/toast';

import './order.css'
import './useOrderService.js'
import useOrderService from './useOrderService.js'

const saveButtonOptions = {
    text: "Enregistrer",
    type: 'default',
    useSubmitBehavior: true,
}

export function Order() {    

    const { 
        state,
        error,
        loading,
        notificationState,
        crmContext,
        handleClientChange,        
        handleBirthDateChange,
        handleCivilityChange,
        handleResidenceCountryChange,
        handleProductChange,
        handlePropertyTypeChange,
        handleSubscriptionDateChange,
        handleSignatureDateBsChange,
        handleSignatureDateQeeriChange,
        handleSignaturePlaceChange,
        handleSharesChange,
        handleAmountChange,
        handleUnitPriceChange,
        handleStatusChange,
        getCivilityOptions ,
        getCountriesRef,
        getProductsRef,
        getPropertyTypesRef,
        getStatusRef,
        handleSubmit,
        onHidingNotification
    } = useOrderService()

    const civilityOptions = getCivilityOptions()
    const countriesOptions = getCountriesRef()
    const productsOptions = getProductsRef()
    const propertyTypesOptions = getPropertyTypesRef()
    const statusOptions = getStatusRef()
    //const maxDate =  new Date().setFullYear(new Date().getFullYear() - 18)

    return (
        <div className="form-container">   
        <Box
          direction="row"
          width="100%"
          height={75}>
          <Item ratio={1}>
           </Item>
            <Item ratio={1}>
                <div>{loading && <h4>Loading...</h4>} </div>
            </Item>  
            <Item ratio={1}>
                <div>{error && 'Error! : '+ error }</div>
            </Item> 
        </Box>        
            
            <Toast
                visible={notificationState.isVisible}
                type={notificationState.type}
                message={notificationState.message}
                onHiding={onHidingNotification}    
            >   
                <Position
                    my="top"
                    at="top"
                    of="#container"
                />
            </Toast>
        <form onSubmit={handleSubmit}>
            <Form
                colCount={3}
                id="form"
                action=""
                formData={state}
                showValidationSummary={true}
                onSubmit={handleSubmit}>     
                <GroupItem />
                <GroupItem />
                <GroupItem >                      
                    <SimpleItem dataField="subscription.status"
                                editorType="dxSelectBox" 
                                editorOptions={{
                                    key: "id",
                                    dataSource: statusOptions,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    onValueChanged : handleStatusChange,
                                    name : "status",
                                    showClearButton: true}}>
                        <Label text={formatMessage('subscriptionStatus')}/>
                    </SimpleItem>
                </GroupItem>     
                <GroupItem caption={formatMessage('general')}>
                    <SimpleItem dataField="subscription.uniqueIdentifier">
                        <Label text={formatMessage('subscriptionUniqueIdentifier')}/>
                    </SimpleItem>                      
                    <SimpleItem dataField="associate.code"
                                editorOptions={{readOnly :true}} >
                        <Label text={formatMessage('associateCode')}/>
                    </SimpleItem>
                    <SimpleItem dataField="associate.title"
                                editorOptions={{readOnly :true}} >
                        <Label text={formatMessage('associateTitle')}/>
                    </SimpleItem>
                </GroupItem>
                <GroupItem caption={formatMessage('subscriber')}>                          
                    <SimpleItem dataField="client.civility"
                                editorType="dxSelectBox" 
                                editorOptions={{
                                    key: "id",
                                    dataSource: civilityOptions,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    onValueChanged : handleCivilityChange,
                                    name : "civility",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientCivility')}/>
                    </SimpleItem> 
                    <SimpleItem dataField="client.firstName"
                                editorOptions={{
                                    onValueChanged : handleClientChange,
                                    name:"firstName",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientFirstName')}/>
                        <RequiredRule message="Le prénom est obligatoire" />
                        <PatternRule message="N'utilisez pas de chiffres dans le prénom"
                        pattern={/^[^0-9]+$/} />
                    </SimpleItem>
                    <SimpleItem dataField="client.lastName"
                                editorOptions={{
                                    onValueChanged : handleClientChange,
                                    name:"lastName",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientLastName')}/>
                        <RequiredRule message="Le nom est obligatoire" />
                        <PatternRule message="N'utilisez pas de chiffres dans le nom"
                        pattern={/^[^0-9]+$/} />
                    </SimpleItem>
                    <SimpleItem dataField="client.birthDate"
                                editorType="dxDateBox"
                                editorOptions={{
                                    onValueChanged : handleBirthDateChange,
                                    name:"birthDate",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientBirthDate')}/>
                        <RequiredRule message="La date de naissance est obligatoire" />
                        {/* <RangeRule max={maxDate} message="L'age minimum pour souscrire est 18 ans " /> */}
                    </SimpleItem> 
                    <SimpleItem dataField="client.age"
                                visible={crmContext && crmContext.isAdmin}
                                 editorOptions={{
                                    readOnly :true,
                                    name:"age"}}>
                        <Label text={formatMessage('clientAge')}/>
                    </SimpleItem>                               
                </GroupItem>   
                <GroupItem caption={formatMessage('address')}>
                    <SimpleItem dataField="client.address" 
                                editorOptions={{
                                    onValueChanged : handleClientChange,
                                    name:"address",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientAddress')}/>
                    </SimpleItem>                                          
                    <SimpleItem dataField="client.residenceCountry"
                                 editorType="dxSelectBox" 
                                 editorOptions={{                                    
                                    key: "id",
                                    dataSource: countriesOptions,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    searchEnabled : true,
                                    onValueChanged: handleResidenceCountryChange,
                                    name : "residenceCountry",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientResidenceCountry')}/>
                    </SimpleItem>
                    <SimpleItem dataField="client.zipcode"
                                editorOptions={{
                                    onValueChanged : handleClientChange,
                                    name:"zipcode",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientZipcode')}/>
                    </SimpleItem>
                    <SimpleItem dataField="client.city"
                                editorOptions={{
                                    onValueChanged : handleClientChange,
                                    name:"city",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientCity')}/>
                    </SimpleItem>
                </GroupItem>
                <GroupItem caption={formatMessage('contact')}>
                    <SimpleItem dataField="client.phone" 
                                editorOptions={{
                                    onValueChanged : handleClientChange,
                                    name:"phone",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientPhone')}/>
                    </SimpleItem>
                    <SimpleItem dataField="client.mobilePhone"
                                editorOptions={{
                                    onValueChanged : handleClientChange,
                                    name:"mobilePhone",
                                    showClearButton: true}}>
                        <Label text={formatMessage('clientMobilePhone')}/>
                    </SimpleItem>
                    <SimpleItem dataField="client.email"
                                editorOptions={{
                                    onValueChanged : handleClientChange,
                                    name:"email",
                                    showClearButton: true}}>
                        <RequiredRule message="L'e-mail est obligatoire" />
                        <EmailRule message="L'adresse e-mail est invalide" />
                        <Label text={formatMessage('clientEmail')}/>
                    </SimpleItem>
                </GroupItem>
                <GroupItem caption={formatMessage('subscription')}>                    
                    <SimpleItem dataField="subscription.product"
                               editorType="dxSelectBox" 
                               editorOptions={{
                                showClearButton: true,
                                  key: "id",
                                  dataSource: productsOptions,
                                  displayExpr: "name",
                                  valueExpr: "id",
                                  searchEnabled: true,
                                  onValueChanged:handleProductChange,
                                  name : "product",
                                  showClearButton: true}}>
                        <Label text={formatMessage('subscriptionProduct')}/>
                        <RequiredRule message="Le produit est obligatoire" />
                    </SimpleItem>
                    <SimpleItem dataField="subscription.unitPrice"
                                editorType="dxNumberBox"
                                format="currency"                               
                                editorOptions={{         
                                    format: 'currency',                        
                                    onValueChanged:handleUnitPriceChange,
                                    readOnly :true,
                                    name : "unitPrice"}}>
                        <Label text={formatMessage('subscriptionUnitPrice')}/>
                    </SimpleItem>          
                    <SimpleItem dataField="subscription.shareType"
                                editorType="dxSelectBox" 
                                editorOptions={{
                                    key: "id",
                                    dataSource: propertyTypesOptions,
                                    displayExpr: "name",
                                    valueExpr: "id",
                                    onValueChanged : handlePropertyTypeChange,
                                    name : "shareType"}}>
                        <Label text={formatMessage('subscriptionShareType')}/>                        
                        <RequiredRule message="Le type de propriété est obligatoire" />
                    </SimpleItem>          
                    <SimpleItem dataField="subscription.amount"
                                editorType="dxNumberBox"
                                format="currency"
                                editorOptions={{
                                    format: 'currency',
                                    onValueChanged: handleAmountChange,
                                    name: "amount",
                                    showClearButton: true}}>
                        <Label text={formatMessage('subscriptionAmount')}/>
                        <RequiredRule message="Le montant est obligatoire" />
                    </SimpleItem>
                    <SimpleItem dataField="subscription.share"
                                editorType="dxNumberBox"
                                editorOptions={{
                                    readOnly: true,
                                    onValueChanged: handleSharesChange,
                                    name: "share"
                                }}>
                        <Label text={formatMessage('subscriptionShare')}/>
                    </SimpleItem>
                    <SimpleItem dataField="subscription.subscriptionDate"
                                editorType="dxDateBox"
                                editorOptions={{
                                    onValueChanged: handleSubscriptionDateChange,
                                    name: "subscriptionDate",
                                    showClearButton: true}}>
                        <Label text={formatMessage('subscriptionDate')}/>
                    </SimpleItem>                   
                </GroupItem>  
                <GroupItem caption={formatMessage('signature')}>
                    <SimpleItem dataField="signature.date"
                                editorType="dxDateBox"
                                editorOptions={{
                                    onValueChanged: handleSignatureDateBsChange,
                                    name: "signatureDateBs",
                                    showClearButton: true}}>
                        <Label text={formatMessage('signatureDate')}/>
                    </SimpleItem>
                    <SimpleItem dataField="signature.location"
                                editorOptions={{
                                    onValueChanged: handleSignaturePlaceChange,
                                    name: "signatureLocation",
                                    showClearButton: true}}>
                        <Label text={formatMessage('signatureLocation')}/>
                    </SimpleItem>
                    <SimpleItem dataField="signature.isSignatureOk"
                                editorType="dxCheckBox"
                                editorOptions={{
                                    readOnly: true,                                    
                                    name: "isSignatureOk",
                                    showClearButton: true}}>
                        <Label text={formatMessage('isSignatureOk')}/>
                    </SimpleItem> 
                    <SimpleItem dataField="subscription.signatureDateQeeri"
                                editorType="dxDateBox"
                                editorOptions={{
                                    onValueChanged: handleSignatureDateQeeriChange,
                                    name: "signatureDateQeeri",
                                    showClearButton: true}}>
                        <Label text={formatMessage('signatureDateQeeri')}/>
                    </SimpleItem>                          
                </GroupItem>
                <GroupItem />
                <GroupItem />
                <GroupItem >                      
                    <ButtonItem buttonOptions={saveButtonOptions} />                    
                </GroupItem>                         
            </Form>
        </form>
    </div>
    )    
}