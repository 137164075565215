
  const locales = [
    {
      Name: 'English',
      Value: 'en',
    },
    {
      Name: 'French',
      Value: 'fr',
    }
  ];
  
  const dictionary = {
    en: {
      subscriptionStatus: 'Status',
      subscriptionUniqueIdentifier: 'Subscription ID',
      associateCode: 'Associate code',
      associateTitle: 'Title',
      clientCivility: 'Civility',
      clientFirstName: 'First name',
      clientLastName: 'Last name',
      clientBirthDate: 'BirthDate',
      clientAge: 'Age',
      clientAddress: 'Address',
      clientResidenceCountry: 'Country',
      clientZipcode: 'Zip code',
      clientCity: 'City',
      clientPhone: 'Phone',
      clientMobilePhone: 'Mobile phone',
      clientEmail: 'Email',
      subscriptionProduct: 'Product',      
      subscriptionUnitPrice: 'Share price',
      subscriptionShareType: 'Share type',
      subscriptionAmount: 'Amount',
      subscriptionShare: 'Number of shares',
      subscriptionDate: 'Subscription date',
      signatureDate: 'Signature BS date',
      signatureLocation: 'Signature place',
      isSignatureOk: 'Signature Ok',
      signatureDateQeeri: 'Siganture QEERI date',
      general: 'General',
      address: 'Address',
      subscription: 'Subscription',
      subscriber: 'Subscriber',
      contact: 'Contact',
      signature: 'Signature'
    },
    fr: {
      subscriptionStatus: 'Statut',
      subscriptionUniqueIdentifier: 'ID souscription',
      associateCode: 'Code associé',
      associateTitle: 'Intitulé',
      clientCivility: 'Civilité',
      clientFirstName: 'Prénom',
      clientLastName: 'Nom',
      clientBirthDate: 'Date de naissance',
      clientAge: 'Age',
      clientAddress: 'Adresse',
      clientResidenceCountry: 'Pays',
      clientZipcode: 'Code postal',
      clientCity: 'Ville',
      clientPhone: 'Téléphone',
      clientMobilePhone: 'Portable',
      clientEmail: 'E-mail',
      subscriptionProduct: 'Produit',
      subscriptionUnitPrice: 'Prix de la part',
      subscriptionShareType: 'Type de propriété',
      subscriptionAmount: 'Montant',
      subscriptionShare: 'Nombre de parts',
      subscriptionDate: 'Date de souscription',
      signatureDate: 'Date de signature',
      signatureLocation: 'Lieu de signature',
      isSignatureOk: 'Signature Ok',
      subscriptionSignatureDateQeeri: 'Date de siganture QEERI',
      general: 'Général',
      address: 'Adresse',
      subscription: 'Souscription',
      subscriber: 'Souscripteur',
      contact: 'Contact',
      signature: 'Signature'
    }
  };
  
  export default {
    getLocales() {
      return locales;
    },
    getDictionary() {
      return dictionary;
    },
  };
  