export const orders = [{
    id: 'efd2bf01-23cf-ec11-a7b5-000d3a65ef86',
    uniqueIdentifier: 'ORD-220509-2043082806',
    product: 'CC',
    shareType: 'Pleine propriété',
    share: 52.0000,
    amount :60000.00,
    subscriptionDate : new Date(2022, 5, 10), 
    associateCode: "058630",
    fullName: "TESTCARPENTIER",
    status: 'Règlement en attente'
},
{
    id: '8823d485-bad2-ec11-8261-0022483884d9',
    uniqueIdentifier: 'ORD-order-updated-1652445906129',
    product: 'CC',
    shareType: 'Pleine propriété',
    share: 2.0000,
    amount : 2150.00,
    subscriptionDate : new Date(2022, 5, 10), 
    associateCode: '',
    fullName: "Adrien GUEMY",
    status: 'A confirmer'	
}]